.d-none{
    display: none !important;
}

.portfolio-flex-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.webs-display{
    width: 35%;
    height: 65vh;
    margin-left: 10%;
    margin-top: 10%;
    overflow-y: auto;
    scrollbar-color: #708eb4 #e0e0e0;
    scrollbar-width: thin;
    display: flex;
    flex-direction: column;
}

.web-badge{
    width: 85%;
    height: 10vh;
    min-height: 10vh;
    line-height: 10vh;
    padding-left: 1vw;
    padding-right: 1vw;
    border-radius: 16px;
    background-color: #161616;
    color: #708eb4;
    font-family: 'Coolvetica';
    font-size: 24px;
    margin-bottom: 5vh;
}

.web-badge:hover{
    cursor: pointer;
    background-color: #383838;
}

.web-link:hover{
    cursor: pointer;
    color: #93b4e0;
}

.web-info{
    border-radius: 16px;
    background-color: #161616;
    width: 35%;
    height: 65vh;
    margin-right: 10%;
    margin-top: 10%;
}

.web-title{
    color: #708eb4;
    font-family: 'Coolvetica';
    font-size: 24px;
    margin-left: 4%;
    margin-top: 3%;
    margin-bottom: 3%;
}

.web-icons{
    margin-left: 4%;
    margin-top: 3%;
    margin-bottom: 3%;
    font-size: 28px;
}

.web-icons > *{
    margin-right: 2%;
}

.web-stack{
    font-size: 36px;
    margin-left: 4%;
    margin-bottom: 3%;
}

.web-text{
    padding-left: 4%;
    padding-right: 2vw;
    font-size: 1.35rem;
    color: #708eb4;
    font-family: Helvetica;
}

.web-pic-container{
    background-color: #708eb4;
    margin-top: 6%;
    margin-left: 4%;
    margin-right: 4%;
    border-radius: 16px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.web-pic-container img{
    width: 75%;
    height: 85%;
    border-radius: 8px;
}

#complex{
    margin-bottom: 0;
}

@media screen and (max-width: 1366px){
    .portfolio-flex-container{
        display: flex;
        flex-direction: column;
    }

    .webs-display{
        width: 100%;
        height: 65vh;
        margin-top: 10%;
        overflow-y: auto;
        scrollbar-color: #708eb4 #e0e0e0;
        scrollbar-width: thin;
        display: flex;
        flex-direction: column;
    }

    .web-info{
        border-radius: 16px;
        background-color: #161616;
        width: 90%;
        margin-left: 5%;
        height: 900px;
        margin-top: 10%;
    }

    .web-pic-container{
        background-color: #708eb4;
        margin-top: 6%;
        margin-left: 15%;
        margin-right: 4%;
        border-radius: 16px;
        height: 475px;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}