.stage-cube-cont {
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
}
  
.cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background: rgba(255, 255, 255, 0.4);
        text-align: center;
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px lightyellow;
    }

    .face1 {
        transform: translateZ(100px);
        color: #EFD81D;
    }
    .face2 {
        transform: rotateY(90deg) translateZ(100px);
        color: #f06529;
    }
    .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
        color: #28a4d9;
    }
    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        color: #5ed4f4;
    }
    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
        color: #FF0000;
    }
    .face6 {
        transform: rotateX(-90deg) translateZ(100px);
        color: #00ad00;
    }

    @keyframes spincube{
        from,
        to {
            transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
        }
        16% {
            transform: rotateY(-90deg) rotateZ(90deg);
        }
        33% {
            transform: rotateY(-90deg) rotateX(90deg);
        }
        50% {
            transform: rotateY(-180deg) rotateZ(90deg);
        }
        66% {
            transform: rotateY(-270deg) rotateX(90deg);
        }
        83% {
            transform: rotateX(90deg);
        }
    }
}

#about-text{
    font-size: 16px;
    text-align: justify;
}

.flat-button{
    color: #708eb4;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #708eb4;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover{
        background: #708eb4;
        color: #333;
    }
}

#cv-button:hover{
    cursor: pointer;
}

@media screen and (max-width: 1200px){
    .stage-cube-cont{
        margin-top: 10vh;
        position: initial;
        width: 100%;
        height: 0%;
        overflow: visible;
    }

    #about-text{
        font-size: 13px;
    }
}