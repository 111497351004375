.text-zone{
    margin-top: -10rem;
}

.contact-form{
    position: absolute;
    top: 45%;
    left: 10%;      

    ul{
        padding: 0;
        margin: 0;        

        li{            
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;            
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;            
        }
    }

    .half{
        display: flex;
        flex-direction: row;
        > li{
            &:first-child{
                margin-right: 2%;
            }
        }
    }

    input[type='text'],
    input[type='email']{
        width: 100%;
        border: 0;
        background-color: #44465c;
        height: 50px;
        font-size: 16px;
        color: #FFF;
        padding: 0 20px;
        box-sizing: border-box;
        font-family: Arial, Helvetica, sans-serif;
    }

    input:focus{
        outline: none;
    }

    textarea{
        width: 100%;
        border: 0;
        background: #44465c;
        height: 50px;
        font-size: 16px;
        color: #FFF;
        padding: 20px;
        box-sizing: border-box;
        min-height: 150px;
        font-family: Arial, Helvetica, sans-serif;
    }

    textarea:focus{
        outline: none;
    }

    .flat-button{
        font-family: Arial, Helvetica, sans-serif;
        color: #708eb4;
        font-size: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid #708eb4;
        float: left;
        border-radius: 4px;
        background: 0 0;
        text-transform: uppercase;
        float: right;
        text-align: center;
        margin-right: 10px;

        &:hover{
            background: #708eb4;
            color: #333;
        }
    }
}

.picture{
    position: absolute;
    display: block;
    top: 35%;
    left: 60%;
    z-index: 999999;
    width: 100%;
    height: 100%;    
    background-image: url(../../assets/images/email.png);
    background-size: 25%;
    background-repeat: no-repeat;
    opacity: 0;
    animation: fadeInDown 2.5s 2.5s;
    animation-fill-mode: forwards;  
}

@media screen and (max-width:1200px){
    .contact-form{
        top: 90%;
        left: 20px;
    }

    .picture{
        display: none;
    }
}