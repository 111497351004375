.logo-container{
    z-index: 0;
    width: 400px;
    height: 609px;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;

    svg{
        width: 100%;
        height: auto;
        bottom: 0;
        transform: rotateZ(30deg) !important;
    }

    .solid-logo{
        position: absolute;
        top: -9px;
        margin-left: 2px;
        width: 100%;
        opacity: 0;
        transform :rotateZ(30deg);
        z-index: 1;
        scale: 92%;
    }
}