.timeline-container{
    position: absolute;
    left: 15%;
    top: 30%;
    overflow-y: visible !important;
    display: flex;
    flex-direction: column;
    width: 70%;
}

.timeline-event{
    display: flex;
    flex-direction: row;
}

.timeline-event:nth-child(odd) {
    flex-direction: row-reverse;
}

.timeline-icon-container-left{
    width: 70px;
    height: 70px;
    margin-right: 10px;
    margin-top: 10px;
}

.timeline-icon-container-right{
    width: 70px;
    height: 70px;
    margin-left: 10px;
    margin-top: 10px;
}

.timeline-icon{
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1200px){
    .timeline-container{
        top: 75%;
        left: 5%;
    }
}